/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-09 15:59:50
 * @Module: 小视频详情
 */
 <template>
  <div class="detail"
       v-if="detailData">
    <detail-userinfo :data="detailData" />
    <div class="videoDetail-content">
      <div class="videoDetail-content-text">
        <at-text-render :text="detailData.moment_content.content"
                        :data="detailData" />
      </div>
      <video :src="detailData.moment_content.play_address"
             controls
             width="248px"
             :height="248/ratio+'px'"
             :poster="detailData.moment_content.video_cover_info?detailData.moment_content.video_cover_info.src:false"></video>
    </div>
    <tip-off :data="detailData"
             @onLike="onLike"
             @onTipOff="onTipOff" />
    <column :data="detailData" />
    <comment :data="detailData" />
    <recommend :data="detailData" />
    <menu-list :data="detailData" />
  </div>
</template>
 <script>
import detailMixins from "./detailMixins"
import atTextRender from "@/components/atTextRender"
export default {
  components: {
    atTextRender
  },
  mixins: [detailMixins],
  data () {
    return {};
  },
  mounted () { },
  methods: {},
  computed: {
    ratio () {
      const { width, height } = this.detailData.moment_content.video_cover_info
      return width / height
    }
  },
};
 </script>
 <style lang='scss' scoped>
@import url("./style.scss");
.videoDetail-text {
  font-size: 16px;
  color: #333333;
  line-height: 25px;
}
.videoDetail-content-text {
  font-size: 16px;
  color: #333;
  margin-top: 14px;
  line-height: 25px;
}
</style>